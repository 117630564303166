<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      d="M24,7.791a2,2,0,0,0-3.851-.75H3.851a2.009,2.009,0,1,0-.1,1.7v8.877A2.842,2.842,0,0,0,4.919,19.8l5.833,3.837a2.361,2.361,0,0,0,2.492,0L19.08,19.8l0,0a2.84,2.84,0,0,0,1.166-2.179V8.739A1.993,1.993,0,0,0,24,7.791ZM15.072,17.64a.524.524,0,0,1-.746.655l-2.2-1.237a.249.249,0,0,0-.245,0L9.678,18.3a.524.524,0,0,1-.746-.655L9.821,15.6a.248.248,0,0,0-.063-.287L8.158,13.9a.489.489,0,0,1,.344-.855h1.835a.25.25,0,0,0,.23-.151l.964-2.236a.529.529,0,0,1,.941,0l.963,2.236a.249.249,0,0,0,.229.151H15.5a.49.49,0,0,1,.346.855l-1.6,1.415a.25.25,0,0,0-.064.287Z"
    />
    <path
      stroke="currentColor"
      d="M11.839,2.144a.249.249,0,0,1,.321,0l4.008,3.34a.251.251,0,0,0,.16.057h2.343a.249.249,0,0,0,.235-.164.251.251,0,0,0-.075-.278L13.28.474a2,2,0,0,0-2.56,0L5.169,5.1a.251.251,0,0,0-.075.278.249.249,0,0,0,.235.164H7.672a.248.248,0,0,0,.16-.058Z"
    />
  </svg>
</template>
